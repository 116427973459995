// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
// $primary: #404040;
$primary: #6ba0ac;
// $primary: #00838f;
// $secondary: #cbc1c1;
$secondary: #595959;
$success: #3e8d63;
$info: #7854e4;
$warning: #def702;
$danger: #d62518;
// $light: #d9d9d9;
// $light: #f1f1f1;
$light: #f3f8f9;
// $light: #7a7a7a;
// $light: #7a7a7a;

// $light: #4f4e4e;
$darkerLight: #e2dfdf;
// $darkerLight: #000000;
$dark: #343a40;
$accent: #da6d25;
// $dashboard: #c9dce0;
// $dashboard: #dfe6e767;
$dashboard: #d0dee063;
// $white: #ffffff;
$background: #d9d9d9;
$grey: #b5b1b1;
$heatmapDark: #c5d7d9;
$test: #6ba0ac;
$text: #000000;

$font-family-primary: 'Roboto', sans-serif;

//#bcd9de
//#6aa0ac
//#575757
//#595959
//F3F8F9
//#c9dce0

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  darkerLight: $darkerLight,
  dark: $dark,
  // add any additional color below
  accent: $accent,
  background: $background,
  dashboard: $dashboard,
  test: $test,
  text: $text
    // now bg-accent,btn-accent,etc.. can be called,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

@import './mdb.pro.scss';

// $my-theme-primary-light: #741357;
// $my-theme-secondary-light: #03cc01;

// $my-theme: mdb-light-theme($my-theme-primary-light, $my-theme-secondary-light);

// $client-theme-primary: #616161;
// $client-theme-secondary: #03cc01;

// $client-theme: mdb-light-theme($client-theme-primary, $client-theme-secondary);

// // include theme styles
// .my-theme-skin {
//   @include mdb-theme($my-theme);
// }

// .client-theme-skin {
//   @include mdb-theme($client-theme);
// }

.form-control:focus {
  border-color: $primary !important;
  box-shadow: inset 0px 0px 0px 1px $primary;
}

.form-control:focus ~ .form-label {
  color: $primary !important;
}

.form-control:focus.is-invalid {
  border-color: #d62518 !important;
}

body,
html {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // background-color: #3c3b3a !important;
  font-family: $font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
    min-height: 1px;
  }
  .avoid-page-break {
    // margin-top: 40mm;
    // display: block;
    // page-break-inside: avoid;
    // min-height: 1px;
    // position: absolute;
  }
  .print-filter {
    position: absolute;
    page-break-before: always;
  }
  .hide-when-printing {
    display: none;
  }
}

@media screen {
  .show-when-printing {
    display: none;
  }
  .page-break {
    display: none;
  }
}

@page {
  size: auto;
  margin-bottom: 5mm;
  margin-top: 5mm;
  margin-left: 25mm;
  margin-right: 25mm;
}

.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
  font-family: $font-family-primary !important;
}

.pvtCheckContainer p:hover .pvtOnly {
  // display: none !important;
}

.navbar {
  box-shadow: none !important;
  /* border-bottom-width: 4rem !important;
  border-color: black !important; */
}
.btn {
  box-shadow: none !important;
  text-transform: unset !important;
  /* background-color: aliceblue !important; */
  /* background: transparent !important; */
  /* text-transform: lowercase !important; */
}
.nav-tabs .nav-link {
  text-transform: unset !important;
}

.pvtUi {
  /* Hela pivottabellen */
  color: rgb(0, 0, 0) !important;

  /* background-color: #000000 !important; */
  background-color: #ffffff !important;
  /* width: 100% !important; */
  font-family: $font-family-primary;
}

.pvtAxisContainer {
  background-color: #ffffff !important;
  /* background-color: $primary !important; */
  border: none !important;
  font-family: $font-family-primary !important;
}

.pvtVals {
  /* sektionen med sortering */
  /* background-color: #ffffff !important; */
  border: none !important;
  display: none !important;
}

.pvtRenderers {
  /* background-color: #301212 !important; */
  display: none !important;
}

.pvtRowOrder {
  display: none !important;
}
.pvtColOrder {
  display: none !important;
}

.pvtFilterBox {
  background-color: #ffffff !important;
  border-radius: 2%;
  padding: 1px;
  width: auto !important;
  font-size: 14px !important;
  font-family: $font-family-primary !important;
}
.pvtFilterBox input {
  width: 80% !important;
}

.pvtFilterBox h4 {
  margin: 10px;
  font-size: 14px !important;
}

.pvtButton {
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 2%;
  padding: 1px;
  width: auto !important;
  font-size: 14px !important;
}

.pvtDropdown {
  /* graf/tabell dropdown och  aggregering*/
  display: none !important;
}

.pvtCheckContainer {
  font-family: $font-family-primary !important;
  font-size: 14px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  // background-color: $primary !important;
}

// .pvtOnlySpacer {
//   display: none !important;
// }

.selected {
  background-color: $dashboard !important;
  color: $text !important;
}

/* .pvtVertList {
  
}
.pvtHorizList {

} */
.pvtRows {
  display: none !important;
  background-color: #c41b59 !important;
}

.pvtUnused {
  background-color: rgb(255, 255, 255) !important;
  /* background-color: $primary !important; */
}

/* .css-1jllj6i-control:hover {
  border-color: $primary !important;
} */

/* .pvtRendererArea {
  
} */

.pvtOutput {
  background-color: #ffffff !important;
  color: $primary !important;

  display: none !important;
}

/* .pvtTable {
  background-color: #e78787 !important;
  padding: 3px !important;
} */

.pvtTable {
  font-family: $font-family-primary !important;
  font-size: 12px !important;
}

.pvtRowLabel {
  background-color: $dashboard !important;
  color: #000 !important;
  font-weight: normal !important;
  font-size: 12px !important;
  // border-color: $dark !important;
}

.pvtAxisLabel {
  // background-color: $heatmapDark !important;
  background-color: $dashboard;
  font-size: 12px !important;
  font-family: $font-family-primary !important;
}
.pvtColLabel {
  // background-color: $heatmapDark !important;
  background-color: $dashboard;
  font-weight: normal !important;
  color: #000 !important;
  font-size: 12px !important;
}
.pvtTotalLabel {
  background-color: $dashboard !important;
  color: #dfe6e700 !important;
  font-size: 12px !important;
}

// table.pvtTable thead tr th, table.pvtTable tbody tr th {

// }

.pvtTable thead tr th {
  background-color: $dashboard !important;
  border-top-color: $heatmapDark !important;
}
.pvtTable tbody tr th {
  background-color: $dashboard !important;
  // background-color: #ffffff !important;
  border-top-color: $heatmapDark !important;
  // font-size: 12px !important;
}

.pvtAttr {
  font-size: 14px !important;

  /* background-color: #c9dce0 !important; */
  background-color: #ffffff !important;
}

.pvtFilteredAttribute {
  /* color: #ffffff !important; */
  color: $primary !important;
  /* background-color: $primary !important; */
  background-color: #ffffff !important;
}

.pvtAxisContainer li {
  padding: 1px !important;
  /* background-color: $primary !important; */
  background-color: #ffffff !important;
}

/* .datatable thead {
 
} */

.table {
  border-top: none !important;
}

.table > :not(:first-child) {
  border-top: 0px solid currentColor;
}

table.mdb-dataTable thead th,
table thead.mdb-dataTable-head th {
  font-weight: 600 !important;

  width: auto !important;
}

.css-1r4vtzz {
  background-color: transparent !important;

  color: #595959 !important;
  /* font-size: small !important; */
  font-size: 14px !important;
  border: none !important;
  outline: none !important;
  padding: 0% !important;
  box-shadow: none !important;
  transition: none !important;
}

.css-48ayfv {
  background-color: transparent !important;
  color: #595959 !important;
  font-size: small !important;
  border: none !important;
  outline: none !important;
  padding: 0% !important;
  box-shadow: none !important;
  transition: none !important;
}
.css-1v99tuv {
  font-family: $font-family-primary;
  font-size: 14px !important;
}

.datepicker-date-text {
  font-family: $font-family-primary !important;
}

.datepicker {
  font-family: $font-family-primary !important;
}
.datepicker-modal-container {
  font-family: $font-family-primary !important;
  height: 400px !important;
}

.datepicker-header {
  font-family: $font-family-primary !important;
  background-color: $primary !important;
  color: #ffffff !important;
  height: 0% !important;
}

.datepicker-cell.selected .datepicker-cell-content {
  font-family: $font-family-primary !important;
  background-color: $primary !important;
  color: #ffffff !important;
}

.datepicker-footer-btn {
  font-family: $font-family-primary !important;
  color: $primary !important;
  text-transform: capitalize !important;
  font-weight: bolder !important;
}

.datepicker-cell.selected {
  font-family: $font-family-primary !important;
  background-color: transparent !important;
}

/* .form-control {
  display: block !important;
  width: 80% !important;
} */

.form-outline {
  width: 80% !important;
  margin-top: 8px !important;
}

.datepicker-toggle-button:hover {
  color: $primary !important;
}
.datepicker-toggle-button {
  color: $primary !important;
}

.datatable-sort-icon {
  opacity: 0 !important;
}

.wdr-ui div,
#wdr-pivot-view .wdr-ui p,
#wdr-pivot-view .wdr-ui a,
#wdr-pivot-view .wdr-ui table,
#wdr-pivot-view .wdr-ui table th,
#wdr-pivot-view .wdr-ui table tr,
#wdr-pivot-view .wdr-ui table td,
#wdr-pivot-view .wdr-ui ul,
#wdr-pivot-view .wdr-ui li,
#wdr-pivot-view .wdr-ui input,
#wdr-pivot-view .wdr-ui textarea,
#wdr-pivot-view .wdr-ui select,
#wdr-toolbar-wrapper .wdr-toolbar-ui,
#wdr-toolbar-wrapper .wdr-toolbar-ui span,
#wdr-toolbar-wrapper .wdr-toolbar-ui div,
#wdr-toolbar-wrapper .wdr-toolbar-ui p,
#wdr-toolbar-wrapper .wdr-toolbar-ui a,
#wdr-toolbar-wrapper .wdr-toolbar-ui table,
#wdr-toolbar-wrapper .wdr-toolbar-ui table th,
#wdr-toolbar-wrapper .wdr-toolbar-ui table tr,
#wdr-toolbar-wrapper .wdr-toolbar-ui table td,
#wdr-toolbar-wrapper .wdr-toolbar-ui ul,
#wdr-toolbar-wrapper .wdr-toolbar-ui li,
#wdr-toolbar-wrapper .wdr-toolbar-ui input,
#wdr-toolbar-wrapper .wdr-toolbar-ui textarea,
#wdr-toolbar-wrapper .wdr-toolbar-ui select {
  font-family: $font-family-primary !important;
  font-size: 12px !important;
  text-transform: none !important;
}
